import React from 'react'

import './Style.css';
import './Media-styles.css';


import TE from './img/topend.webp';
import PBuild from './img/pbuild.webp';
import PAA from './img/paa.webp';
import Buildon from './img/buildon.webp';
import HiEnd from './img/hi-end.webp';
import PGlass from './img/pglass.webp';
import Acoustic from './img/acoustic.webp';
import Ano from './img/ano.webp';
import Briggs from './img/briggs.webp';






function Colours() {
    return (
        <>


            <div className='inner_container'>

                <div className='inner-body-container'>
                    <div className='inner_body'>
                        
                        
                        <div className='colour-grid'>
                            <div className='colour-col col1 first grayscale-image'><a href="https://ceiling.net.au/" target="_blank" rel="noreferrer"><img src={TE} alt="Yang's Group of companies" /></a></div>
                            <div className='colour-col col2 second grayscale-image'><a href="https://www.planetbuildingproducts.com.au/" target="_blank" rel="noreferrer"><img src={PBuild} alt="Yang's Group of companies" /></a></div>
                            <div className='colour-col col3 third grayscale-image'><a href="https://www.paa.net.au/" target="_blank" rel="noreferrer"><img src={PAA} alt="Yang's Group of companies" /></a></div>


                        </div>
                        <div className='colour-grid'>
                            <div className='colour-col col4 fourth grayscale-image'><a href="https://www.buildon.net.au/" target="_blank" rel="noreferrer"><img src={Buildon} alt="Yang's Group of companies" /></a></div>
                            <div className='colour-col col5 fifth grayscale-image'><a href="#" target="_blank" rel="noreferrer"><img src={HiEnd} alt="Yang's Group of companies" /></a></div>
                            <div className='colour-col col6 seventh grayscale-image'><a href="https://planetglass.com.au/" target="_blank" rel="noreferrer"><img src={PGlass} alt="Yang's Group of companies" /></a></div>


                        </div>
                        <div className='colour-grid'>
                            <div className='colour-col col7 eighth grayscale-image'><a href="https://acoustic.com.au/" target="_blank" rel="noreferrer"><img src={Acoustic} alt="Yang's Group of companies" /></a></div>
                            <div className='colour-col col8 nineth grayscale-image'><a href="https://www.anodiserswa.com.au/" target="_blank" rel="noreferrer"><img src={Ano} alt="Yang's Group of companies" /></a></div>
                            <div className='colour-col col9 tenth grayscale-image'><a href="https://www.briggs.com.au/" target="_blank" rel="noreferrer"><img src={Briggs} alt="Yang's Group of companies" /></a></div>
                            


                        </div>

                    </div>

                </div>


</div>

          


        </>

    )
}


export default Colours