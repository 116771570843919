import React from 'react'
import Header from './Header';
import Footer from './Footer';
import './Home.css';
import './Media-styles.css';

import Welcome from './Welcome';

import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
function Home() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>yang's group of companies</title>
          <link rel="canonical" href="" />
          
          <meta charSet="utf-8" />
          <meta name="title" content="title" />
          <meta name="description" content="description" />
          <meta name="keywords" content="" />
          <meta property="og:title" content="About og title" />
          <meta property="og:description" content="og-description" />
        </Helmet>
      </HelmetProvider>
      <div className='hm-header'><Header /></div>

      <div className='home-container'>




        <Welcome />



      </div>
      <Footer />
    </>
  )
}

export default Home