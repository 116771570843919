import './App.css';
import './components/Font_style.css';

import './components/Media-styles.css';

import Home from './components/Home';

import Colours from './components/Colours';




import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },



  {
    path: "Colours",
    element: <Colours />,
  },




]);





function App() {
  return (
    <div className="App">

      <RouterProvider router={router} />

    </div>
  );
}

export default App;