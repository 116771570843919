import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <App>
        <Helmet>
          <title>Aqua Technics Canada - Fibreglass Swimming Pools</title>
          <link rel="canonical" href="https://www.aquatechnicspools.ca/" />
        </Helmet>
      </App>
    </React.StrictMode>
  </HelmetProvider>
);