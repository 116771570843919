import React from 'react'

import Colours from './Colours';
import Logo from '../components/img/ygc-logo.png'
function Welcome() {
  return (
    <>



      <div className='body-container'>
        <div className='inner-body-container'>
        
          <div className='inner_body'><img src={Logo} alt="logo" />
            
            <Colours />
          </div>

        </div>
      </div>




    </>
  )
}

export default Welcome