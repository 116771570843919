import React from 'react'
import './Header.css';
import './Media-styles.css';
// import Navigation from './Navigation';
// import Logo from './img/2024Logos_1024x365px.webp'


function Header() {
  return (
    <>


      <div id='header-container'>

        <div className='header-logo-navbar'>
          <div className='header-left'></div>

          <div className='header-right'></div>

        </div>
      </div>
    </>
  )
}

export default Header