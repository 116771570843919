import React from 'react'
import './Footer.css';
import './Media-styles.css';


import { Helmet } from 'react-helmet';


function Footer() {
  return (
    <>
      <Helmet>
        <title>Yang's Group of companies</title>
       
        <meta name="title" content="Yang's Group of companies" />
        <meta name="description" content="Yang's Group of companies" />
        <meta name="keywords" content="" />
        <meta property="og:title" content="About og title" />
        <meta property="og:description" content="og-description" />
      </Helmet>

      <div className='ft_main_container'>

        <div className='footer-container'>
          <div className='note'>
            <div className='note-col1'></div>
            <div className='note-col2'></div>
            <div className='note-col3'></div>
            <div className='note-col4'></div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Footer;